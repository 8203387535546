

.slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.slider-container {
  display: flex;
  transition: transform 0.5s ease;
}

.slider-card {
  flex: 0 0 100%;
  padding: 20px;
  text-align: center;
}

.slider-image {
  width: 100%;
  height: auto;
}
