/* SplashScreen.css */
body {
    margin: 0;
    padding: 0;
  }
  
  .splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('src/assets/image/copy.png'); 
    background-size: cover;
    background-position: center;
  }
  
  .splash-screen-content {
    opacity: 0; 
    animation: fadeIn 1.5s forwards;
  }
  
  .visible .splash-screen-content {
    opacity: 1; /* Make content visible */
  }
  
  .hidden .splash-screen-content {
    opacity: 0; /* Hide content */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  