html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif; 
}

.bg-blue {
    background-color: #3490dc; 
    background-image: url('src/assets/image/bg.png'); 
    background-size: cover; 
    background-position: center; 
    
  }
  

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.flex-grow {
  flex-grow: 1;
}

.min-h-screen {
  min-height: 100vh;
}

/* Table styles ) */
table {
  width: 100%;
  border-collapse: collapse;
}

td {
  padding: 8px;
  border: 2px solid #ccc;
}

/* Button styles */
button {
  padding: 10px 20px;
  background-color: #FFD700;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease-in-out; 
}

button:hover {
  background-color: #FFC400; 
}