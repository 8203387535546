/* FallingCoinsAnimation.css */

/* Your background image URL */
body {
    background-image: url('src/assets/image/copy.png');
    background-size: cover; 
    height: 100vh;
    z-index: -1;
   
  }
  
  .falling-coins-container {
    /* Your container styles */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
  }
  
  .snow {
    
    position: absolute;
    width: 44px;
    height: 44px;
    opacity: 0;
    animation: spin 2s linear infinite, fall linear infinite; 
  }
  
  @keyframes spin {
    /* Define spinning keyframes */
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fall {
    /* Define falling keyframes */
    /* 0% {
      opacity: 1;
      transform: translateY(20px) scale(0);
    } */
    100% {
      opacity: 1;
      transform: translateY(100vh) scale(1);
    }
  }
  
 
  .snow.snow-1 {
    /* Define specific styles for snowflake 1 */
  }
 
  