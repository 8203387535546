/* Balls.css */
@keyframes slideFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .circle {
    position: relative;
    top: 5px;
    left: 5px;
    text-align: center;
    width: 55px;
    height: 55px;
    /* width: 100px;
    height: 100px; */
    border-radius: 100%;
    background-color: #ffffff;
    padding:35px
  }

  .big-circle {
    position: relative;
    top: 5px;
    left: 5px;
    text-align: center;
    width: 55px;
    height: 55px;
    /* width: 100px;
    height: 100px; */
    border-radius: 100%;
    background-color: #ffffff;
    padding:55px
  }
  
  .circle-border {
    position: relative;
    text-align: center;
    width: 65px;
    height: 65px;
    /* width: 110px;
    height: 110px; */
    border-radius: 100%;
    background-color: black;
    
  }